import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const TestHomePage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const username = useSelector(state => state.auth.username);

    const handleLogout = () => {
        // Dispatch logout action
        dispatch({ type: 'LOGOUT' });
        navigate('/login');
    };

    const handleClick = (path) => {
        navigate(`/${path}`);
    };

    return (
        <div className="homepage">
            <div>{username ?? 'User Not Authenticated'}</div>
        </div>
    );
};

export default TestHomePage;