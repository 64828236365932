import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const me = createAsyncThunk('auth/me', async (_, thunkAPI) => {
  console.log('process check auth') 
  try {
    const URL = 'https://genai-api.nawatech.co/api/users/get-role';
    // const URL = 'http://localhost:8000/auth/check-auth/';
    const response = await axios.get(URL, {
      headers: {
        'x-api-key': 'e1a38197a2c0fa11176188725b6e206dc824203f',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      },
      withCredentials: true,
    });
    if(response.status === 200){
      return thunkAPI.fulfillWithValue(response.data.data)
    }else{
      new Error(response.statusText)
    }
  } catch (error) {
    console.error('failed to fetch', error)
    return thunkAPI.rejectWithValue("Invalid Credentials")
  }
})

const initialState = {
  user: {},
  isUserLoggedIn: null,
  isLoading: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.isLoading = true;
    },
    logout: (state) => {
      state.user = {};
      state.isUserLoggedIn = null;
      state.isLoading = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(me.pending, (state) => {
      state.isLoading = true;
      console.log('pending')
    });
    builder.addCase(me.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isUserLoggedIn = true;
      state.isLoading = false;
      console.log('fullfiled')
    });
    builder.addCase(me.rejected, (state) => {
      state.isUserLoggedIn = null;
      state.isLoading = false;
      console.log('rejected')
    });
  }
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
