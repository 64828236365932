import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from "./LoginPage/LoginPage"
import Homepage from "./Homepage/Homepage";
import MVDWarroom from './MVDWarroom/MVDWarroom';
import MVDInvestor from './MVDInvestor/MVDInvestor';
import "./App.css";
import PrivateRoutes from './auth/PrivateRoutes';
import TestPrivateRoutes from './auth/TestPrivateRoutes';
import TestLoginPage from './auth/TestLoginPage';
import TestHomePage from './auth/TestHomePage';
import DashboardValidator from './auth/DashboardValidator';
import NotFoundPage from './NotFoundPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to={'/home'}/>} />
        <Route path="/login" element={<LoginPage />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/home" element={<Homepage />} />
          <Route element={<DashboardValidator />}>
            <Route path="/war-room/:dashboard_id" element={<MVDWarroom />} />
          </Route>
          {/* <Route path="/investor" element={<MVDInvestor />} /> */}
        </Route>
        <Route element={<TestPrivateRoutes />}>
          <Route path="/test-home" element={<TestHomePage />} />
          {/* <Route path="/login" element={<TestLoginPage />} /> */}
        </Route>
        <Route
          path="*"
          element={<NotFoundPage />}
        />
      </Routes>
    </Router>
  );
}

export default App;