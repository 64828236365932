import React, { useState, useEffect } from 'react';
import SinarmasImage from "../assets/sinarmas.png"
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import "./LoginPage.css"
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { login } from '../redux/slices/authSlice';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // uncomment once sso is ready

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const queryToken = urlParams.get('token'); 
  //   const queryNik = urlParams.get('nik'); 

  //   if (queryToken && queryNik) {
  //     sessionStorage.setItem('nik', queryNik);
  //     navigate('/home');
  //   }
  // }, [navigate]);

  // const handleLogin = () => {
  //   //change to sinarmas sso url
  //   const ssoUrl = `http://localhost:3001?nik=${nik}`;

  //   window.location.href = ssoUrl;
  // };

  const [queryParams] = useSearchParams();
  if(queryParams.get('access_token')){
    localStorage.setItem('access_token', queryParams.get('access_token'));
    return <Navigate to='/home' />
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const URL = "https://genai-api.nawatech.co/api/users/login";
      // const URL = "http://localhost:8000/api/users/login";
      const response = await axios.post(URL, {
        nik: username,
        password: password
      }, {
        headers: {
          'x-api-key': 'e1a38197a2c0fa11176188725b6e206dc824203f',
        },
      })
      if (response.status === 200) {
        localStorage.setItem('access_token', response.data.access_token);
        setIsLoading(false);
        dispatch(login());
        navigate('/home')
      }
    } catch (error) {
      console.error('failed to fetch', error)
      console.log(error.response);
      if (error.response.status === 401) {
        setErrorMessage('Invalid nik or password');
      }
      setIsLoading(false);
    };
  };

  const handleLoginWithSSO = () => {
    const ORIGIN = window.origin;
    window.location.href = `https://genai-api.nawatech.co/auth/login?relay_state=${ORIGIN}/login`;
  }

  return (
    <div className="login-container">
      <img src={SinarmasImage} alt="Sinarmas Logo" className="logo" />
      <div className="disclaimer">
        Please do not put in your real password, this is just for testing purposes.
      </div>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <form onSubmit={handleLogin}>
        <div className="login-form">
          <input
            type="text"
            placeholder="Enter NIK"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required={true}
          />
          <input
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required={true}
          />
          <button type='submit'>Login</button>
        </div>
      </form>
      <button onClick={handleLoginWithSSO} className='btn-style-reset' disabled={isLoading}>Login With SSO</button>
    </div>
  );
};

export default LoginPage;